body {
    font-family: "vwfs-sans",Verdana,Arial,sans-serif;
    font-weight: inherit;
}

.o-main > .o-content-section {
    background: no-repeat center;
}

#main-form {
    border: 1px solid #dcdcdc;
}

.c-input__input.is-error~.c-input__addon::before, .c-input__input.is-success~.c-input__addon::before, .c-input__input.is-error~.c-input__state-icon::before, .c-input__input.is-success~.c-input__state-icon::before {
    display: none;
}

.c-input__input.is-success, .c-input__input.is-success~.c-input__addon, .c-input__input.is-success~.c-input__state-icon {
    box-shadow: none;
}

.c-input__input.is-success, .c-input__input.is-success~.c-input__addon {
    border-right: none;
}

.c-input__input.is-success~.c-input__state-icon {
    border-left: none;
}

.c-input.is-success {
    box-shadow: 0 0 0 1px #038364;
}

.c-header {
    margin-bottom: 0px !important;
}

#main-form {
    background: #004666;
    color: white !important;
    opacity: 0.9;
    max-width: 750px;
}

.c-form-field__label, .c-form-heading__title {
    color: white;
}

.TextoLegal {
    border-collapse: collapse; 
    width:100%; 
}

.TextoLegal > tbody > tr > td {
    border: 0px solid black;text-align:left;padding:6px;
}

.c-form-field__label {
    font-size: 0.9rem;
}

.TextoLegal > tbody > tr > td {
    font-size: 0.6rem;
    vertical-align: top;
}

.TextoLegal > thead > td {
    font-size: 1rem;
    padding: 6px;
}

.TextoLegal > tbody > tr > td > span {
    cursor: pointer;
    text-decoration: underline;
}

.checkbox > ul > li > label {
    color: white !important;
}

.c-footer
{
    background-color: #004666;
}

.c-footer-footnotes {
    border-top: 0px;
}